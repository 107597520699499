import React from 'react'
import { useNavigate } from 'react-router-dom';
import { GAME_URL } from '../../config/constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { Game } from '../model/dto/res/game';
import { connect } from 'react-redux';
import { PlatformActionType, PopupType } from '../redux/reducers/types';

interface Props {
    game: Game;
    userloginDetails?: any;
    updateUserLoginStatus?: ()=> void;
}

const GameCard = (props: Props) => {
    const navigation = useNavigate();

    const openGame = () => {
        if (!props?.userloginDetails) {
            if (props.updateUserLoginStatus) {
                props.updateUserLoginStatus();
            }
        }
        navigation(`/detail/${props.game?._id}?_sF=likes`)
    }
    return (
        <div className="card pointer rounded-4 h-100" onClick={openGame}>
            <img src={GAME_URL + props.game?.icon} className={`card-image-top mp-game-icon rounded-top-4`} alt="..." />
            <div className="game-card-title py-2">
                <div className="text-white text-start ps-3 text-capitalize">{props.game?.name}</div>
                <div className="d-flex px-3 text-white justify-content-between">
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon style={{ fontSize: 12 }} icon={faStar}></FontAwesomeIcon>
                        <small className='ms-1'>{props.game?.avgRating || 0}</small>
                    </div>
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon style={{ fontSize: 12 }} icon={faThumbsUp}></FontAwesomeIcon>
                        <small className='ms-1'>{props.game?.total_likes}</small>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    userloginDetails: state.UserStore.user
});

const mapDispatchToProps = (dispatch: any) => ({
    updateUserLoginStatus: () => dispatch({ data: PopupType.LOGIN, type: PlatformActionType.LOGIN }),
})

export default connect(mapStateToProps, mapDispatchToProps)(GameCard);
