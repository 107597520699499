import React, { useEffect, useState } from 'react'
import { MoplayCache } from '../app/service/moplay-cache';
import { StatusGames } from '../app/service/statusgame.service';
import { NavLink, useNavigate } from 'react-router-dom';
import { PopupType } from '../app/redux/reducers/types';
import { connect } from 'react-redux';
import GameCard from '../app/components/game-card';

interface Props {
    userdata: any;
    notificationPopup?: (data: any) => void;
    showLoginPopup?: () => void;
}

const HomePage = (props: Props) => {
    const [gameData, setGameData] = useState<any[]>([]);
    const navigation = useNavigate();
    const [activeTab, setActiveTab] = useState('');
    const [availableCategories, setAvailableCategories] = useState<string[]>([]);

    const handleTabClick = (tabId: string) => {
        const categoryElement = document.getElementById(`category_${tabId}`);
        console.log('category element ', categoryElement);

        if (categoryElement) {
            categoryElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth'
            })
        }
        setActiveTab(tabId);
    };

    useEffect(() => {
        loadGames();
    }, [])

    const loadGames = () => {
        StatusGames('').then((res: any) => {
            let items = [];
            let cats: string[] = [];
            MoplayCache.games.allGames = res;
            for (let index = 0; index < res.length; index++) {
                const element = res[index];
                const catIndex = cats.findIndex(el => el === element.category);
                if (catIndex < 0) {
                    cats.push(element.category);
                }

                items.push(element);
            }
            setGameData(items);
            setAvailableCategories(cats);
            setActiveTab(cats[0]);
        });
    }

    const openGame = (data: any) => {
        navigation(`/detail/${data?._id}?_sF=home-page`)
    }

    const onProfileNavigation = () => {
        if (!props?.userdata?.user) {
            if (props.showLoginPopup) {
                props.showLoginPopup();
            }
        } else {
            navigation("/profile")
        }
    }

    return (
        <div className='text-center' style={{ paddingBottom: 200 }}>
            <div className='position-sticky d-lg-none top-0 bg-light' style={{ zIndex: 10 }}>
                <div className='py-2'>
                    <div className='d-flex overflow-x-auto mp-scroll-width-none'>
                        <ul className="nav nav-tabs border-0 d-flex overflow-auto mp-scroll-width-none flex-nowrap" id="myTab" role="tablist">
                            {availableCategories.map((tab) => (
                                <li className="nav-item d-flex" role="presentation" key={tab}>
                                    <button
                                        className={`nav-link text-dark text-nowrap f-14 border-0 px-3 px-md-3 ${activeTab === tab ? 'mp-home-page-active fw-bold' : ''}`}
                                        id={`${tab}-tab`} onClick={() => handleTabClick(tab)}
                                        data-bs-toggle="tab" type="button" role="tab"
                                        aria-controls={tab} aria-selected={activeTab === tab}
                                    >
                                        {tab}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            {
                availableCategories.map(el => <div className='container px-3 pt-4 py-4' id={`category_${el}`} key={`category_${el}`} style={{
                    // boxShadow: '2px 2px 5px 0px #e4e1e1'
                }}>
                    <div className='text-start'><strong className='text-dark text-start'>{el}</strong></div>
                    <div className='overflow-auto mp-scroll-width-none position-relative' style={{ height: 200 }}>
                        {
                            gameData.filter(_game => _game.category === el).map((game, index) =>
                                <div style={{ width: 150, height: 200, left: index * 180 }} className={`px-1 py-2 position-absolute`} key={game._id}>
                                    <GameCard game={game}></GameCard>
                                    {/* <div className="card pointer rounded-4 h-100" onClick={() => openGame(game)}>
                                        <img src={GAME_URL + game?.icon} className={`card-image-top mp-game-icon rounded-top-4`} alt="..." />
                                        <div className="game-card-title py-2">
                                            <div className="text-white text-start ps-3 text-capitalize">{game?.name}</div>
                                            <div className="d-flex px-3 text-white justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <FontAwesomeIcon style={{ fontSize: 12 }} icon={faStar}></FontAwesomeIcon>
                                                    <small className='ms-1'>4.2</small>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <FontAwesomeIcon style={{ fontSize: 12 }} icon={faThumbsUp}></FontAwesomeIcon>
                                                    <small className='ms-1'>{game.total_likes}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            )
                        }
                    </div>
                </div>)
            }
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    userdata: state.UserStore
});
const mapDispatchToProps = (dispatch: any) => ({
    showLoginPopup: () => dispatch({ data: PopupType.LOGIN, type: 'popup' }),
})
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);